import React, {useState} from 'react';
import { Swipeable, direction } from 'react-deck-swiper';
import toast, { Toaster } from 'react-hot-toast';

import Card from './Card'
import SwipeButtons from './SwipeButtons';
import styles from './App.module.css';
import content from './content.json'

function App() {
  const testAbout = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla aliquet enim augue, et dapibus eros volutpat dictum. Duis maximus libero id auctor finibus. Donec erat magna, feugiat id aliquam ac. "
  console.log('content', content);

  function randomElementGenerator(array) {
    var copy = array.slice(0);
    return function() {
      if (copy.length < 1) { copy = array.slice(0); }
      var index = Math.floor(Math.random() * copy.length);
      var item = copy[index];
      copy.splice(index, 1);
      return item;
    };
  }

  const generateCard = () => {
    let about = '';
    let used = 0;
    let generator = randomElementGenerator(content.abouts);

    while(about.split(' ').length < 30 && used < content.abouts.length) {
      let interpunction = Math.random() < 0.5 ? '.' : '!';
      about = about + generator() + `${interpunction} `;
      used += 1;
    }

    const imgIndex = Math.floor(Math.random() * content.numPics);

    return {
      about,
      image: `/images/${imgIndex}.jpg`
    };
  }

  let [cards, setCards] = useState({
    current: generateCard(),
    next: generateCard()
  });

  const [cancelAttempts, setcancelAttempts] = useState(0);

  const onSwipe = (swipeDirection) => {
    if(swipeDirection == direction.LEFT) {
      const errLevel = cancelAttempts > 2 ? "high" : "low";
      const generator = randomElementGenerator(content.errors[errLevel]);

      toast.error(generator());

      setcancelAttempts(a => a+1);
    } else if(swipeDirection == direction.RIGHT){
      setCards(({ next }) => ({ current: {...next}, next: generateCard() }));
      setcancelAttempts(0);
      toast.success('Savršen match!');
    }

    //setSwiping({ left: true, right: false });
  }

  return (
    <div className={styles.app}>
      <h1 className={styles.heading}>Pauloo</h1>

      <div style={{ position: 'relative' }}>
        <div style={{ zIndex: 2, position: 'relative', height: '77vh' }}>  
          <Swipeable 
          onSwipe={onSwipe}
          renderButtons={({left, right}) => <SwipeButtons left={left} right={right} />}
          >
            <div className='card' style={{ position:'absolute', top: 0, width: '100%' }}>
              <Card about={cards.current.about} image={cards.current.image}/>
            </div>
          </Swipeable>
        </div>   
        {
        <div style={{ position:'absolute', top: 0, zIndex: 1, width: '100%' }}>
          <Card about={cards.next.about} image={cards.next.image}/>
        </div> 
        }  
      </div>

      <Toaster position='bottom-center' /> 
    </div>
  );
}

export default App;
