import React from 'react';
import styles from './swipe.module.css'

import { ReactComponent as CloseIcon } from './close.svg';

const ButtonClose = ({ onClick }) => {
  return (
    <div className={styles.closeContainer} onClick={onClick}>
      <CloseIcon className={styles.closeIcon} />
    </div>
  );
};

export default ButtonClose;