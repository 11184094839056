import React from 'react';
import styles from './swipe.module.css'

import { ReactComponent as HeartIcon } from './heart.svg';

const ButtonHeart = ({ onClick }) => {
  return (
    <div className={styles.heartContainer} onClick={onClick}>
      <HeartIcon className={styles.heartIcon} />
    </div>
  );
}

export default ButtonHeart;