import React from 'react';
import styles from './swipe.module.css'

import ButtonClose from './ButtonClose';
import ButtonHeart from './ButtonHeart'

const SwipeButtons = ({left, right }) => {
  return (
    <div className={styles.container}>
      <ButtonClose onClick={left} />
      <ButtonHeart onClick={right} />
    </div>
  );
};

export default SwipeButtons;