import React from "react";
import styles from "./card.module.css"

import { ReactComponent as LocationIcon} from './location.svg'

const Card = ({ image, about, zIndex }) => {
  return (
    <div className={styles.container} style={{zIndex}}>
      <div className={styles.content}>
        <img src={image} className={styles.image} alt=""></img>
        <div className={styles.info}>
          <p className={styles.name}>Paula</p>
          <div className={styles.locationContainer}>
            <LocationIcon className={styles.locationIcon} /> 
            <p className={styles.locationText}>Velika Mlaka</p>
          </div>

          <p className={styles.about}>{about}</p>
        </div>
      </div>
    </div>
  );
};

export default Card;
